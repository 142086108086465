import { type ClassValue, clsx } from 'clsx'
import { format } from 'date-fns'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const assertUnreachable = (x: never): never => {
  throw new Error(`Didn't expect to ${JSON.stringify(x)} get here`)
}

export const formatDate = (date: Date) => format(date, 'MMMM dd, yyyy')

export const getAssetCDNUrl = (fileKey: string) =>
  `http://${process.env.NEXT_PUBLIC_APP_S3_CLOUDFRONT_DOMAIN}/${fileKey}`

export const getCookieFromHeaders = (cookieName: string, cookiesString: string = '') => {
  const cookies = {} as Record<string, string> // Object to store parsed cookies

  // Parse the cookie header string
  cookiesString.split(';').forEach((cookie) => {
    const [name, value] = cookie.trim().split('=')
    cookies[name] = value
  })

  // Retrieve the value of the specified cookie by name
  return cookies[cookieName] || null
}
